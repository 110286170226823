.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.outer-block {
  padding: 8px 40px;
}

.centered-block {
  display: flex;
  max-width: 880px;
  margin: auto;
}

.pointed-block-left {
  border-width: medium;
  border: solid #d9d9d9;
  /* grey.theree */
  border-radius: 22px 22px 22px 0px;
  padding: 1.5rem;
}

.pointed-block-right {
  border-width: medium;
  border: solid #d9d9d9;
  /* grey.theree */
  border-radius: 22px 22px 0px 22px;
  padding: 1.5rem;
}

.custom-container {
  display: flex;
  max-width: 1440px;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}

.project-content-container {
  max-width: 880px;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.project-section {
  margin: 8rem 0;
}

.round-image {
  width: 100%;
  border-radius: 8px;
  margin: 1rem 0;
  height: auto;
  object-fit: contain;
}

.user-quote {
  border-left: 5px solid rgb(217, 217, 217);
  padding: 10px 20px;
  margin-left: 0px;
}